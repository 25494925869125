<!-- =========================================================================================
  File Name: ClientSelectPrompt.vue
  Description: Попап выбора посетителя по штрих-коду
========================================================================================== -->
<template>
  <vs-prompt
      ref="clientIdPrompt"
      @accept="acceptPrompt"
      @close="promptClientBarcode=''"
      @cancel="promptClientBarcode=''"
      title="Введите штрихкод карты посетителя"
      accept-text="Выбрать"
      cancel-text="Отмена"
      :active.sync="activeVsPrompt">
    <vs-input ref="clientIdInput" placeholder="Штрихкод" v-model="promptClientBarcode" class="mt-3 w-full" />
  </vs-prompt>
</template>

<script>
export default {
    name: 'client-select-prompt',
    props: {
      activePrompt: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        activeVsPrompt: false,
        promptClientBarcode: null
      }
    },
    watch: {
      activeVsPrompt: function(val) {
        if (val) {
          setTimeout(() => { this.$refs.clientIdPrompt.$scopedSlots.default()[0].context.$refs.clientIdInput.focusInput(); }, 0);
        }
        this.$emit('update:activePrompt', this.activeVsPrompt);
      },
      activePrompt() {
        this.activeVsPrompt = this.activePrompt
      }
    },
    methods: {
      async acceptPrompt(){
        // TODO maker decorator global function
        this.$vs.loading();
        await this.$store.dispatch('startClientSession', this.promptClientBarcode, this.$router.push('/').catch(() => {}));
        this.promptClientBarcode = '';
        this.$vs.loading.close();
      }
    }
}
</script>
